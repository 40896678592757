


window.addEventListener('DOMContentLoaded', function (e) {
  function flip() {
    let flipbook = document.querySelector('#flipbook')
    let nextButton = flipbook.querySelector('.next-button')
    let previousButton = flipbook.querySelector('.previous-button')
    let pageWrapper = flipbook.querySelector('.page-wrapper')


    if (window.innerWidth < 1170) {
    
    }

    Object.assign(nextButton.style, {
      height: '100%'
    })
    Object.assign(previousButton.style, {
      height: '100%'
    })
  }
  
  flip()
  window.addEventListener('resize', function (e) {
    flip()
  }) 
})